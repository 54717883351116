<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item :label="$t('图片')" prop="imgUrl" >
        <template>
          <div class="clearfix">
            <a-upload action="http://47.245.94.137:7070/admin-api/common/upload" list-type="picture-card"
              :file-list="fileList" @preview="handlePreview" @change="handleChange">
              <div v-if="fileList.length < 1">
                <a-icon type="plus" />
                <div class="ant-upload-text">
                  {{ $t('上传图片') }}
                </div>
              </div>
            </a-upload>
            <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
              <img alt="example" style="width: 100%" :src="form.imgUrl" />
            </a-modal>
          </div>
        </template>
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            {{ $t('保存') }}
          </a-button>
          <a-button type="dashed" @click="cancel">
            {{ $t('取消') }}
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getLogo, addLogo, updateLogo } from '@/api/door/logo'

export default {
  name: 'CreateForm',
  props: {
  },
  components: {
  },
  data () {
    return {
      submitLoading: false,
      formTitle: '',
      previewVisible: false,
      fileList: [
      ],
      // 表单参数
      form: {
        id: null,

        imgUrl: null,

        createTime: null,

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        imgUrl: [
          { required: true, message: this.$t('地址不能为空'), trigger: 'blur' }
        ]
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        imgUrl: null,
        createTime: null,
      }
      this.$nextTick(() => {
        this.$refs.form.resetFields()
      })
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    // /** 修改按钮操作 */
    // handleUpdate (row, ids) {
    //   this.reset()
    //   this.formType = 2
    //   const id = row ? row.id : ids
    //   getLogo({"id":id}).then(response => {
    //     this.form = response.data
    //     this.open = true
    //     this.formTitle = '修改'
    //   })
    // },
   /** 修改按钮操作 */
   handleUpdate(row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getLogo({ "id": id }).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
        this.fileList[0] = {
          uid: '-1',
          name: 'image.png',
          status: 'done',
          url: response.data.imgUrl
        }
        this.videoList[0] = {
          uid: '-1',
          name: 'video.mp4',
          status: 'done',
          url: response.data.vedioUrl
        }
      })
    },

    handleCancel() {
      this.previewVisible = false;
    },

    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      this.previewImage = file.url || file.preview;
      this.previewVisible = true;
    },
    handleChange(info) {
      console.log(info)
      this.fileList = info.fileList
      this.fileList.forEach(item => {
        if (item.status === 'done') {
          if (item.response.resultCode !== 1) {
            // item.status = 'error'
            // item.url = undefined
          } else {
            item.url = `${this.url_}${item.response.data}`
          }
          this.form.imgUrl=item.response.data
        }
      })
   console.log(this.form.imgUrl)
    //   this.fileList = fileList;
    //   this.fileList.forEach(element => {
    //     console.log("文件上传" + element.response)
    //     this.form.imgUrl=element.url
    //   });
     },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateLogo(this.form).then(response => {
              this.$message.success(
                this.$t('修改成功'),
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addLogo(this.form).then(response => {
              this.$message.success(
                this.$t('新增成功'),
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
